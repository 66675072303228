<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="''">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds"
							style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="goodsType" label="卖品类型">
						<a-select v-model:value="formState.goodsType" style="width: 190px;" placeholder="请选择卖品类型">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">普通卖品</a-select-option>
							<a-select-option :value="5">套餐卖品</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row v-show="showAll">
					<a-form-item name="goodsName" class="ui-form__item" label="卖品名称">
						<a-input v-model:value="formState.goodsName" placeholder="请输入卖品名称"></a-input>
					</a-form-item>
					
					<a-form-item name="generalGoodsTitle" class="ui-form__item" label="卖品单品名称">
						<a-input v-model:value="formState.generalGoodsTitle" placeholder="请输入卖品单品名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item name="orderNo" class="ui-form__item" label="卖品订单号">
						<a-input v-model:value="formState.orderNo" placeholder="请输入会员昵称"></a-input>
					</a-form-item>

					<a-form-item name="verificationCode" class="ui-form__item" label="核销码">
						<a-input v-model:value="formState.verificationCode" placeholder="核销码"></a-input>
					</a-form-item>

					<a-form-item name="payType" class="ui-form__item" label="支付类型">
						<a-select v-model:value="formState.payType" style="width: 190px;" placeholder="请选择支付类型">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">现金支付</a-select-option>
							<a-select-option :value="2">卖品券</a-select-option>
							<a-select-option :value="3">混合支付</a-select-option>
							<a-select-option :value="4">后台核销</a-select-option>
							<a-select-option :value="6">线上会员卡支付</a-select-option>
							<a-select-option :value="8">影城会员卡支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="核销日期" name="time">
						<a-range-picker v-model:value="formState.time" :disabledDate="disabledDate" />
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['snack_report_verification_export']">
							<ExportReport type="snackStatisticalVerification"
								:searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2400 }"
					tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'week'">
							星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.signTime *1000).day()] }}
						</template>
						<template v-if="column.key === 'time'">
							<div>{{ transDateTime(record.signTime, 1) }}</div>
						</template>

						<template v-if="column.key === 'orderInfo'">
							<p>订单号：<span style="color: #999;">{{ record.orderNo }}</span></p>
							<p>商户流水号：<span style="color: #999;">{{ record.outTradeNo }}</span></p>
						</template>
						
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else> 抖音小程序 </a-tag>
							</div>
						</template>

						<template v-if="column.key === 'signTime'">
							{{ transDateTime(record.signTime, 1) }}
						</template>
						
						<template v-if="column.key === 'snackType'">
							<a-tag v-if="record.goodsType === 1" color="blue">普通卖品</a-tag>
							<a-tag v-if="record.goodsType === 5" color="green">套餐卖品</a-tag>
						</template>
						
						<template v-if="column.key === 'generalGoodsTitle'">
							{{ record.generalGoodsTitle || '--' }}
						</template>
						
						<template v-if="column.key === 'type'">
							<a-tag style="margin-bottom: 8px;" :color="['#87d068', '#f50', '#108ee9', '#2db7f5', '', 'blue', '', '#999'][record.payType - 1]">{{ ['现金支付', '卖品券', '混合支付', '后台核销', '', '线上会员卡', '', '影城会员卡'][record.payType - 1] }}</a-tag>
						</template>
						
						<template v-if="column.key === 'coupon'">
							<div v-if="record.snackUserCouponVOList && record.snackUserCouponVOList.length">
								券号：
								<span v-for="(item, i) in record.snackUserCouponVOList" :key="item.barcode">
									{{ item.barcode }}
									<span v-if="i < record.snackUserCouponVOList.length - 1">、</span>
								</span>
							</div>
							<div v-else>--</div>
						</template>
						
					</template>
					
					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								<div style="text-align: center;">
									合计： {{ total.goodsNum || 0 }}
								</div>
							</a-table-summary-cell>
							<a-table-summary-cell>
							</a-table-summary-cell>
							<a-table-summary-cell>
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { snackStatisticsVerification } from '@/service/modules/report.js';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				showAll: false,
				formState: {
					// organizationId: '',
					cinemaIds: [0],
					goodsType: '',
					time: [],
					source: 0
				},
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				columns: [{
						title: '核销日期',
						key: 'time',
						width: 140
					}, {
						title: '星期',
						key: 'week',
						width: 100
					}, {
						title: '所属影院',
						dataIndex: 'cinemaName'
					}, {
						title: '卖品订单信息',
						key: 'orderInfo',
						width: 330,
					}, {
						title: '卖品名称',
						dataIndex: 'goodsTitle'
					}, {
						title: '卖品类型',
						key: 'snackType',
						width: 120,
						align: 'center'
					}, {
						title: '单品名称',
						key: 'generalGoodsTitle'
					}, {
						title: '单品核销数量',
						dataIndex: 'goodsNum',
						align: 'center',
						width: 120
					}, {
						title: '支付类型',
						key: 'type',
						width: 120,
						align: 'center'
					}, {
						title: '订单来源',
						key: 'source',
						width: 120,
						align: 'center'
					}, {
						title: '使用券信息',
						key: 'coupon'
					}, {
						title:'核销码',
						dataIndex: 'verificationCode'
					}
				],
				searchData: [],
				organizationList: [],
				cinemaAllList: [],
				total: {},
				// cinemaIds: []
			}
		},
		created() {
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				if (!this.searchData.organizationId) {
					this.searchData.organizationId = undefined;
				}
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				if (this.searchData.time && this.searchData.time.length == 2) {
					this.searchData.startTime = this.moment(this.formState.time[0].startOf('day')).unix();
					this.searchData.endTime = this.moment(this.formState.time[1].endOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.searchData = {};
				this.cinemaAllList = [];
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.list = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				delete this.searchData.time;
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let ret = await snackStatisticsVerification(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.total = ret.data.list[0];
						this.list = ret.data.list.splice(1);
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			getCinemaId(value) {
				this.cinemaIds = value;
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			disabledDate(current) {
				return current && current > this.moment().endOf('day');
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-goods {
		padding: 6px 10px;
		border-radius: 10px;
		border: solid 1px #EEE;
	}
	.ui-goods + .ui-goods {
		margin-top: 4px;
	}
</style>
